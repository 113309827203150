import React from 'react';

import BlogView from '../views/blogView/index';

const BlogPage = ({ data }) => {
  return <BlogView data={data} label={'Home'} />;
};

export default BlogPage;

export const pageQuery = graphql`
  {
    allContentfulBlog {
      edges {
        node {
          id
          slug
          category
          postTitle
          postDate
          featuredImage {
            description
            file {
              url
              fileName
              contentType
            }
          }
          postThumbnail {
            description
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
  }
`;
